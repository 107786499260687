body {
  /* Allows us to prevent scrolling scrolling underneath modal (on ios/safari) */
  /* position: fixed; // Enbaling this line will prevent openeing a modal from causing the page to jump to the top but keeping it enabled will cuase the infitnite scrol on  end reach function to never ber called....*/

  /* Undoes layout being broken by position: fixexd */
  width: 100%;
  height: 100%;
  overflow-y: scroll;
}

.as-modal__body--after-open {
  overflow-y: hidden;
  position: fixed;
  width: 100%;
  height: 100%;
}

.ReactModal__Content.as-modal {
  padding: 24px 20px 30px 20px;
  display: flex;
  flex-direction: column;
  align-items: stretch;

  background-color: #ffffff;
  box-shadow: 0 10px 20px 0 rgba(32, 53, 83, 0.25);
  border-radius: 15px;

  outline: none;

  width: 90%;
  max-width: 400px;
  position: initial;
  overflow-y: scroll;
}

.ReactModal__Content.as-modal--prevent-scroll {
  overflow-y: hidden;
}

.ReactModal__Content.as-modal--no-padding {
  padding: 0;
}

.ReactModal__Overlay.as-modal {
  position: fixed;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0);
  z-index: 1300;
}

/* Having to wait for --after-open-safari suffix class to fix transitions on safari  */
.ReactModal__Overlay--after-open.as-modal--after-open-safari {
  background-color: rgba(0, 0, 0, 0.15);
  transition: background-color 0.3s ease-out;
}

.ReactModal__Overlay--before-close.as-modal {
  background-color: rgba(0, 0, 0, 0);
  transition: background-color 0.2s ease;
}

@media only screen and (max-width: 770px) {
  .ReactModal__Content.as-modal.as-modal--slide-on-mobile {
    position: fixed;
    bottom: -100vh;
    width: 100%;
    left: 0;
    z-index: 10;
    max-width: initial;
    box-shadow: initial;

    border-radius: 15px 15px 0 0;
    max-height: 80vh;
  }

  /* Having to wait for --after-open-safari suffix class to fix transitions on safari  */
  .ReactModal__Content--after-open.as-modal--after-open-safari.as-modal--slide-on-mobile {
    bottom: 0vh;
    transition: bottom 0.3s ease-out;
  }

  .ReactModal__Content--before-close.as-modal.as-modal--slide-on-mobile {
    bottom: -100vh;
    transition: bottom 0.2s ease-out;
  }

  .ReactModal__Content.as-modal--expand.as-modal--slide-on-mobile {
    min-height: calc(100% - 45px);
  }

  .ReactModal__Content.as-modal.as-modal--no-slide-on-mobile {
    opacity: 0;
  }

  /* Having to wait for --after-open-safari suffix class to fix transitions on safari  */
  .ReactModal__Content--after-open.as-modal--after-open-safari.as-modal--no-slide-on-mobile {
    opacity: 1;
    transition: opacity 0.3s ease-out;
  }

  .ReactModal__Content--before-close.as-modal.as-modal--no-slide-on-mobile {
    opacity: 0;
    transition: opacity 0.2s ease-out;
  }

  .ReactModal__Content.as-modal--agent {
    max-width: 500px !important;
    min-height: 80vh !important;
    max-height: 80vh !important;
    border-radius: 0% !important;
    padding: 0;
  }
}

@media only screen and (min-width: 770px) {
  .ReactModal__Content.as-modal {
    opacity: 0;

    /* Hide scrollbars */
    /* TODO: this is not great as it completely removes the scrollbars but is necessary as a quick fix to prevent the corners with from losing their border radius on desktop (only occurs when using mouse instead of a trackpad on mac) */
    /* TODO: maybe try this? https://www.npmjs.com/package/react-perfect-scrollbar */
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }

  .ReactModal__Content.as-modal::-webkit-scrollbar {
    /* Hide scrollbars (webkit) */
    display: none;
  }

  /* Having to wait for --after-open-safari suffix class to fix transitions on safari  */
  .ReactModal__Content--after-open.as-modal--after-open-safari {
    opacity: 1;
    transition: opacity 0.3s ease-out;
  }

  .ReactModal__Content--before-close.as-modal {
    opacity: 0;
    transition: opacity 0.2s ease-out;
  }

  .ReactModal__Content.as-modal--expand {
    max-width: 698px !important;
    min-height: 75vh;
    max-height: 75vh;
  }

  .ReactModal__Content.as-modal--agent {
    max-width: 500px !important;
    min-height: 75vh;
    max-height: 75vh;
    border-radius: 0%;
    padding: 0;
  }
}
