@media all {
  .page-break {
    display: none;
  }
}

@media print {
  html,
  body {
    position: initial;
    width: initial;
    height: initial;
    -webkit-print-color-adjust: exact;
    margin: 20mm 0;
  }
}

@media print {
  .page-break {
    margin-top: 20mm;
    display: block;
    page-break-before: auto;
  }
}

@page {
  margin-top: 10mm;
}
